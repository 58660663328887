<script>
import Layout from "@/router/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";
import $ from "jquery";

export default {
  page: {
    title: "Profile",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Profile",
      items: [
        {
          text: "Profile",
          active: true,
        },
      ],
      user_roles: localStorage.getItem('session_role'),
      user_id: localStorage.getItem("session_user_id"),
      user_username: "",
      user_name: "",
      user_status: "",
      user_created_at: "",
      user_updated_at: "",
      user_last_login: "",
      user_role_name: "",
      photo_default: "",
      photo_full: "",

      // variable modal
      form_data: false,
      form_photo: false,

      // Catch Error Axios
      axiosCatchErrorProfile: "",
      axiosCatchErrorPhoto: "",

      // variabel edit data
      edit_username: "",
      edit_name: "",
      edit_password: "",
      edit_password_confirm: "",
      edit_photo: "",

      // validasi password
      isMin8Chars: false,
      isSpecialCharacter: false,
      isNumber: false,
      isAlphabet: false,
      isUppercase: false,
      isLowercase: false,
    };
  },
  mounted() {
    const roles = JSON.parse(localStorage.getItem('session_role'));
    this.user_role_name = roles.map(item => item.role.role_name).join(', ');
    this.getData();
  },
  methods: {
    async getData() {
      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
        allowOutsideClick: false,
      });

      const data = await axios({
        method: "get",
        url:
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "master/users/" +
          this.user_id,
        params: {
          id: this.user_id,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      })
        .then((response) => response.data)
        .catch((err) => {
          console.log("err get data:", err);
          return false;
        });

      if (data) {
        console.log(data);
        this.user_username = data.data?.username;
        this.user_name = data.data?.name;
        this.user_status = data.data?.status;
        this.user_created_at = data.data?.created_at;
        this.user_updated_at = data.data?.updated_at;
        this.user_last_login = data.data?.last_login;
        this.user_role_name = localStorage.getItem('session_role_active_name');
        this.photo_default = data.data?.path_photo;
        this.photo_full =
          process.env.VUE_APP_BACKEND_URL + data.data?.path_photo;
        // this.selected_role = JSON.parse(data.data?.selected_role);

        this.edit_username = data.data?.username;
        this.edit_name = data.data?.name;
      }

      Swal.close();
      // axios(config)
      //   .then(function (response) {
      //     console.log("response.data.data", response.data.data);
      //     var response_data = response.data;
      //     var response_data_fix = response_data.data[0];
      //     if (response_data.meta.code == 200) {
      //       const user = response.data.data;
      //       console.log("user:", user);
      //       // var data_edit = response_data_fix;
      //       this.user_username = "tes";
      //       // this.user_username = user.username;
      //       this.user_name = user.name;
      //       this.user_status = user.status;
      //       this.user_created_at = user.created_at;
      //       this.user_updated_at = user.updated_at;
      //       this.user_last_login = user.last_login;
      //       this.user_role_name = user.name_role;
      //       this.photo_default = user.path_photo;
      //       this.photo_full = process.env.VUE_APP_BACKEND_URL + user.path_photo;
      //       this.selected_role = JSON.parse(user.selected_role);

      //       this.edit_username = user.username;
      //       this.edit_name = user.name;

      //       Swal.close();
      //     } else {
      //       Swal.close();
      //       Swal.fire({
      //         icon: "error",
      //         title: "Oops...",
      //         text: response_data_fix.data.message,
      //       });
      //     }
      //   })
      //   .catch(function (error) {
      //     console.log(error);
      //     Swal.close();
      //   });
    },
    fullDateTimeFormat(datetime) {
      if (!datetime) return "-";
      return moment(datetime).format("YYYY-MM-DD HH:mm");
    },
    show_modal_data() {
      this.form_data = true;
    },
    show_modal_photo() {
      let self = this;
      self.form_photo = true;
    },
    StoreDataProfile() {
      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
      });

      var config_store_data = {
        method: "put",
        url:
          process.env.VUE_APP_BACKEND_URL_VERSION +
          `master/users/${this.user_id}/profile`,
        data: {
          id: this.user_id,
          username: this.edit_username,
          password: this.edit_password,
          password_confirm: this.edit_password_confirm,
          name: this.edit_name,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config_store_data)
        .then(function (response) {
          console.log(response);
          Swal.fire({
            icon: "success",
            title: "Berhasil",
            text: "Halaman akan dimuat ulang.",
            timer: 2000,
            timerProgressBar: true,
            showCancelButton: false,
            showConfirmButton: false,
          }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
              Swal.close();
              location.reload();
            }
          });
        })
        .catch(function (error) {
          self.axiosCatchErrorProfile = error.response.data.data;
          Swal.close();
        });
    },
    StoreDataPhoto() {
      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
      });

      var config_store_data = {
        method: "put",
        url:
          process.env.VUE_APP_BACKEND_URL_VERSION +
          `master/users/${this.user_id}/photo`,
        data: {
          id: this.user_id,
          path_photo: this.edit_photo,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config_store_data)
        .then(function (response) {
          console.log(response);
          Swal.fire({
            icon: "success",
            title: "Berhasil",
            text: "Halaman akan dimuat ulang.",
            timer: 2000,
            timerProgressBar: true,
            showCancelButton: false,
            showConfirmButton: false,
          }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
              Swal.close();
              location.reload();
            }
          });
        })
        .catch(function (error) {
          this.axiosCatchErrorProfile = error.response.data.data;
          Swal.close();
        });
    },
    inputFilePhoto() {
      let self = this;
      if ($("#formPhoto")[0].files[0]) {
        if ($("#formPhoto")[0].files[0].size < 2242880) {
          $("#uploadLoading").html(
            '<span class="badge bg-warning p-1"><i class="fa fa-refresh fa-spin"></i> Loading...</span>'
          );
          var urlres = process.env.VUE_APP_BACKEND_URL;
          var FormData = require("form-data");
          var data = new FormData();
          data.append("file", $("#formPhoto")[0].files[0]);
          var config = {
            method: "post",
            url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/upload",
            headers: {
              Authorization: "Bearer " + localStorage.access_token,
            },
            data: data,
          };
          axios(config).then(function (response) {
            console.log(response.data.data.path_file);
            var path_file = response.data.data.path_file;
            urlres += path_file;
            $("#uploadLoading").html(
              '<span class="badge bg-success p-1"><i class="fa fa-check"></i> Berhasil </span> <a href="' +
                urlres +
                '" target="_blank" style="padding-left:10px;"><span class="badge bg-success p-1"><i class="fa fa-eye"></i> Lihat File</span></a>'
            );
            self.edit_photo = path_file;
          });
        } else {
          alert("Max File 2 MB");
        }
      }
    },
    validatePassword(pass) {
      const regextSpecialChar = /[!@#$%^&*(),.?":{}|<>]/;
      const regexNumber = /\d/;
      const regexAlphabet = /[a-zA-Z]/;
      const regexUppercase = /[A-Z]/;
      const regexLowecase = /[a-z]/;

      this.isMin8Chars = pass.length >= 8;
      this.isSpecialCharacter = regextSpecialChar.test(pass);
      this.isNumber = regexNumber.test(pass);
      this.isAlphabet = regexAlphabet.test(pass);
      this.isUppercase = regexUppercase.test(pass);
      this.isLowercase = regexLowecase.test(pass);
    },
    isPassSafe() {
      return (
        this.isMin8Chars &&
        this.isNumber &&
        this.isAlphabet &&
        this.isUppercase &&
        this.isSpecialCharacter &&
        this.isLowercase &&
        this.edit_password == this.edit_password_confirm
      );
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-4">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <h4 class="card-title mb-4">Foto Profil Akun Anda</h4>
              </div>
              <div class="col-md-6">
                <div class="text-end">
                  <button
                    type="button"
                    class="btn btn-success btn-sm"
                    title="Ubah Foto Profil"
                    v-on:click="show_modal_photo"
                  >
                  <i class="fa fa-edit me-1"></i> Edit
                  </button>
                </div>
              </div>
              <div class="col-md-12">
                <center>
                  <div v-if="photo_default == null">
                    <div class="position-relative mb-2">
                      <img
                        src="@/assets/images/users/avatar-9.png"
                        alt="Foto Profil Akun"
                        class="img-thumbnail"
                        style="width: 50%"
                      />
                    </div>
                  </div>
                  <div v-else>
                    <div class="position-relative mb-2">
                      <img
                        v-bind:src="photo_full"
                        alt="Foto Profil Akun"
                        class="img-thumbnail"
                        style="width: 50%"
                      />
                    </div>
                  </div>

                  <h5 class="font-size-15 text-truncate">
                    {{ user_name }}
                  </h5>
                  <p class="text-muted mb-0 text-truncate">
                    {{ user_role_name }}
                  </p>
                </center>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-8">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <h4 class="card-title mb-4">Data Profil Akun Anda</h4>
              </div>
              <div class="col-md-6">
                <div class="text-end">
                  <button
                    type="button"
                    class="btn btn-success btn-sm"
                    title="Ubah Data Profil"
                    v-on:click="show_modal_data"
                  >
                    <i class="fa fa-edit me-1"></i> Edit
                  </button>
                </div>
              </div>
              <!-- <div class="col-md-12">
                <p class="text-muted mb-4">
                  Hi Smartsoft, di halaman ini anda dapat mengubah profil akun
                  anda.
                </p>
              </div> -->
              <div class="col-md-12">
                <div class="table-responsive">
                  <table class="table table-nowrap mb-0">
                    <tbody>
                      <tr>
                        <th scope="row" style="width: 20%">Username</th>
                        <td>{{ user_username }}</td>
                      </tr>
                      <tr>
                        <th scope="row">Nama</th>
                        <td>{{ user_name }}</td>
                      </tr>
                      <tr>
                        <th scope="row">Roles</th>
                        <td>{{ user_role_name }}</td>
                      </tr>
                      <tr>
                        <th scope="row">Status Akun</th>
                        <td>
                          <div v-if="user_status == 'ENABLE'">
                            <div
                              class="badge badge-pill badge-soft-success font-size-12"
                            >
                              ENABLE
                            </div>
                          </div>
                          <div v-else>
                            <div
                              class="badge badge-pill badge-soft-danger font-size-12"
                            >
                              DISABLE
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Terakhir Login ke Aplikasi</th>
                        <td>{{ fullDateTimeFormat(user_last_login) }}</td>
                      </tr>
                      <tr>
                        <th scope="row">Waktu Pembuatan Akun</th>
                        <td>{{ fullDateTimeFormat(user_created_at) }}</td>
                      </tr>
                      <tr>
                        <th scope="row">Waktu Perubahan Data Terakhir</th>
                        <td>{{ fullDateTimeFormat(user_updated_at) }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
  </Layout>

  <b-modal
    v-model="form_data"
    id="modal-lg"
    size="lg"
    title="Ubah Data Profil Akun"
    title-class="font-18"
    hide-footer
  >
    <b-form class="p-2" @submit.prevent="StoreDataProfile">
      <div class="row">
        <div class="col-md-12">
          <div v-if="axiosCatchErrorProfile">
            <div
              class="alert alert-danger"
              v-if="
                typeof axiosCatchErrorProfile === 'string' ||
                axiosCatchErrorProfile instanceof String
              "
            >
              {{ axiosCatchErrorProfile }}
            </div>
            <div class="alert alert-danger" role="alert" v-else>
              <div
                v-for="(errorArray, idx) in axiosCatchErrorProfile"
                :key="idx"
              >
                <div v-for="(allErrors, idx) in errorArray" :key="idx">
                  <span class="text-danger">{{ allErrors[0] }} </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <b-form-group
            class="mb-3"
            label="Username"
            label-for="formrow-username-input"
          >
            <b-form-input
              id="formrow-username-input"
              placeholder="Masukkan Username..."
              type="text"
              v-model="edit_username"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group
            class="mb-3"
            label="Password"
            label-for="formrow-password-input"
          >
            <input
              type="password"
              class="form-control"
              id="formrow-password-input"
              placeholder="Masukkan Password..."
              v-model="edit_password"
              v-on:input="validatePassword(edit_password)"
            />
            <small class="text-danger">
              * Isi jika ingin mengganti password
            </small>
            <div class="d-flex" v-if="edit_password.length">
              <div class="row">
                <small
                  :class="{
                    'text-danger': !isMin8Chars,
                    'text-success': isMin8Chars,
                  }"
                >
                  <i
                    class="fa"
                    :class="{
                      'fa-times': !isMin8Chars,
                      'fa-check': isMin8Chars,
                    }"
                  ></i>
                  Minimal 8 Karakter
                </small>
                <small
                  :class="{
                    'text-danger': !isSpecialCharacter,
                    'text-success': isSpecialCharacter,
                  }"
                >
                  <i
                    class="fa"
                    :class="{
                      'fa-times': !isSpecialCharacter,
                      'fa-check': isSpecialCharacter,
                    }"
                  ></i>
                  Karakter Khusus (!@#$*&)
                </small>
              </div>
              <div class="row">
                <small
                  :class="{
                    'text-danger': !isNumber,
                    'text-success': isNumber,
                  }"
                >
                  <i
                    class="fa"
                    :class="{
                      'fa-times': !isNumber,
                      'fa-check': isNumber,
                    }"
                  ></i>
                  Angka (0...9)
                </small>
                <small
                  :class="{
                    'text-danger': !isAlphabet,
                    'text-success': isAlphabet,
                  }"
                >
                  <i
                    class="fa"
                    :class="{
                      'fa-times': !isAlphabet,
                      'fa-check': isAlphabet,
                    }"
                  ></i>
                  Huruf (a...z / A...Z)
                </small>
              </div>
              <div class="row">
                <small
                  :class="{
                    'text-danger': !isUppercase,
                    'text-success': isUppercase,
                  }"
                >
                  <i
                    class="fa"
                    :class="{
                      'fa-times': !isUppercase,
                      'fa-check': isUppercase,
                    }"
                  ></i>
                  Uppercase (A...Z)
                </small>
                <small
                  :class="{
                    'text-danger': !isLowercase,
                    'text-success': isLowercase,
                  }"
                >
                  <i
                    class="fa"
                    :class="{
                      'fa-times': !isLowercase,
                      'fa-check': isLowercase,
                    }"
                  ></i>
                  Lowercase (a...z)
                </small>
              </div>
            </div>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group
            class="mb-3"
            label="Konfirmasi Password"
            label-for="formrow-konfirmasi-password-input"
          >
            <b-form-input
              id="formrow-konfirmasi-password-input"
              placeholder="Masukkan Konfirmasi Password..."
              type="password"
              v-model="edit_password_confirm"
            ></b-form-input>
            <small
              :class="{
                'text-danger': !(edit_password == edit_password_confirm),
                'text-success': edit_password == edit_password_confirm,
              }"
              v-if="edit_password.length"
            >
              <i
                class="fa"
                :class="{
                  'fa-times': !(edit_password == edit_password_confirm),
                  'fa-check': edit_password == edit_password_confirm,
                }"
              ></i>
              Sama dengan password
            </small>
          </b-form-group>
          <hr />
        </div>
        <div class="col-md-12">
          <b-form-group
            class="mb-3"
            label="Nama"
            label-for="formrow-nama-input"
          >
            <b-form-input
              id="formrow-nama-input"
              placeholder="Masukkan Nama..."
              type="text"
              v-model="edit_name"
            ></b-form-input>
          </b-form-group>
          <hr />
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="text-start">&nbsp;</div>
          </div>
          <div class="col-md-6">
            <div class="text-end">
              <b-button type="reset" variant="danger"
                ><i class="fa fa-redo-alt"></i> Reset</b-button
              >
              &nbsp;
              <b-button
                type="submit"
                variant="primary"
                :disabled="edit_password.length && !isPassSafe()"
              >
                <i class="fa fa-save"></i> Simpan</b-button
              >
            </div>
          </div>
        </div>
      </div>
    </b-form>
  </b-modal>

  <b-modal
    v-model="form_photo"
    id="modal"
    title="Ubah Foto Profil Akun"
    title-class="font-18"
    hide-footer
  >
    <b-form class="p-2" @submit.prevent="StoreDataPhoto">
      <div class="row">
        <div class="col-md-12">
          <div v-if="axiosCatchErrorPhoto">
            <div
              class="alert alert-danger"
              v-if="
                typeof axiosCatchErrorPhoto === 'string' ||
                axiosCatchErrorPhoto instanceof String
              "
            >
              {{ axiosCatchErrorPhoto }}
            </div>
            <div class="alert alert-danger" role="alert" v-else>
              <div v-for="(errorArray, idx) in axiosCatchErrorPhoto" :key="idx">
                <div v-for="(allErrors, idx) in errorArray" :key="idx">
                  <span class="text-danger">{{ allErrors[0] }} </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="mb-3">
            <label for="formPhoto" class="form-label">Pilih Foto</label>
            <input
              class="form-control"
              type="file"
              id="formPhoto"
              v-on:change="inputFilePhoto()"
            />
            <div class="respond-input-file float-left" id="uploadLoading"></div>
            <small class="float-right" style="color: red"
              >*) Max File 2 MB</small
            >
          </div>
          <hr />
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">&nbsp;</div>
        <div class="col-md-6">
          <div class="text-end">
            <b-button type="reset" variant="danger"
              ><i class="fa fa-redo-alt"></i> Reset</b-button
            >
            &nbsp;
            <b-button type="submit" variant="primary"
              ><i class="fa fa-save"></i> Simpan</b-button
            >
          </div>
        </div>
      </div>
    </b-form>
  </b-modal>
</template>
